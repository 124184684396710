$blue: #341cac;
.card {
  width: 280px;
  display: block;
  margin: 60px auto;
  box-shadow: 10px 5px 40px 10px darken(#f5f5f5, 5%);
  transition: 0.25s;
  border: 0px;

  &:hover {
    box-shadow: 10px 5px 40px 10px darken(#f5f5f5, 15%);

    // box-shadow: 10px 5px 40px 20px darken(#341cac, 10%);
    cursor: pointer;

    .button-primary {
      transform: translate(10px, 0);
    }
  }
}

.card-header {
  height: 150px;
  width: 100%;
  padding: 15px;
  width: 100%;
  background-size: cover;
  color: #fff;
}

.card-header--title {
  text-transform: uppercase;
  margin: 0;
}

.card-body {
  padding: 15px;
  background-color: #fff;
  width: 100%;
  h2 {
    font-size: 1.5em;
  }
}

.date {
  font-size: 11px;
  font-weight: 600;
  color: grey;
}

.body-content {
  padding-bottom: 40px;
  font-size: 13px;
  line-height: 1.8;
}

.button-primary {
  border: none;
  box-shadow: none;
  font-family: inherit;
  background-color: transparent;
  color: $blue;
  font-size: 15px;
  transition: 0.25s;

  i {
    padding-right: 4px;
  }
}

.designer-link {
  color: #fff;
  text-align: center;
  font-size: 12px;
  text-decoration: none;
  display: block;
}
