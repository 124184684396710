a{
color:black
}

.silky .slick-prev {
    left: 0 !important;
    z-index:100 !important;
    background-color: #73acff !important;
}

.silky .slick-next {
    right: 0 !important;
    z-index:100 !important;
    background-color: #73acff !important;
}

//.ant-anchor {
//    padding-top: 77px;
//    display:flex;
//}
.ant-anchor-ink-ball{
    display:none;
}

.ant-anchor-link{
    font-size: 19px;
}
